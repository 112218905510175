import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from "vuex-persist"

const vuexLocalStorage = new VuexPersist({
	key: 'ScriptureUnionGhanaApp',
	storage: window.localStorage,
})


Vue.use(Vuex)

export default new Vuex.Store({
	plugins: [vuexLocalStorage.plugin],
    state: {
		user: {},
		devotionalYear: "2021",
		get defaultLessonDate() {
			return new Date(this.devotionalYear, 0, 1)
		},
    },
    mutations: {
		user_login: (state, payload) => {
			state.user = payload
		},
		change_devotional_year: (state, year) => {
			state.devotionalYear = year
		},
    },
    actions: {
		userLogin({commit}, payload){
			commit('user_login', payload)
		},
		changedevotionalYear({ commit }, year){
			commit("change_devotional_year", year)
		}
    },
    modules: {
    }
})
