import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'


// const firebaseConfig = {
// 	apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
// 	authDomain: process.env.VUE_APP_FIREBASE_PROJECT_ID + ".firebaseapp.com",
// 	databaseURL: "https://" + process.env.VUE_APP_FIREBASE_PROJECT_ID + ".firebaseio.com",
// 	projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
// 	storageBucket: process.env.VUE_APP_FIREBASE_PROJECT_ID + ".appspot.com",
// 	messagingSenderId: "381174545480",
// 	appId: "1:381174545480:web:ae45a76523506b7477ec7d",
// 	measurementId: "G-KV9BJMNSQN"
// }

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBYynjUUbrEO3apxBn03AvTkQN7LCpZjwc",
	authDomain: "su-backend.firebaseapp.com",
	databaseURL: "https://su-backend.firebaseio.com",
	projectId: "su-backend",
	storageBucket: "su-backend.appspot.com",
	messagingSenderId: "381174545480",
	appId: "1:381174545480:web:ae45a76523506b7477ec7d",
	measurementId: "G-KV9BJMNSQN"
};

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
// 	apiKey: "AIzaSyDcJeU7hv-c1Y8O5Iz6ZQNJmV2Zl9Mriy0",
// 	authDomain: "su-backend-staging.firebaseapp.com",
// 	databaseURL: "https://su-backend-staging-default-rtdb.firebaseio.com",
// 	projectId: "su-backend-staging",
// 	storageBucket: "su-backend-staging.appspot.com",
// 	messagingSenderId: "356495001105",
// 	appId: "1:356495001105:web:0730d0e74008b5e0ab13b0",
// 	measurementId: "G-RV0T2VJF4W"
// };

firebase.initializeApp(firebaseConfig)

firebase.getCurrentUser = () => {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged(user => {
			unsubscribe()
			resolve(user)
		}, reject)
	})
}

Vue.prototype.$fb = firebase
Vue.prototype.$db = firebase.firestore()
Vue.prototype.$fn = firebase.functions()
Vue.prototype.$st = firebase.storage()
Vue.prototype.$auth = firebase.auth()


import VueIziToast from 'vue-izitoast'
import 'izitoast/dist/css/iziToast.css'
Vue.use(VueIziToast)

import VueMoment from "vue-moment"
Vue.use(VueMoment)

import axios from "axios"
Vue.prototype.$http = axios

import TextareaAutosize from 'vue-textarea-autosize'
Vue.use(TextareaAutosize)

import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
Vue.use(Croppa)

import Datepicker from 'vuejs-datepicker'
Vue.component("datepicker", Datepicker)


import VueSwal from 'vue-swal'
Vue.use(VueSwal)

import ButtonLoader from "@/components/Loaders/Button.vue"
Vue.component("Loader", ButtonLoader)

import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
// Vue.component('datetime', Datetime)
Vue.component('Datetime', Datetime)
Vue.use(Datetime)


Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
