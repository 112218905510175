import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from "firebase/app"
import "firebase/auth"

import DeleteApp from "../views/Auth/DeleteApp.vue"

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: () => import('../views/Home.vue'),
		children: [
			{
				path: "",
				component: () => import("../views/Dashboard/Index.vue"),
			},
			{
				path: "devotionals",
				component: () => import("../views/Devotionals/Index.vue"),
			},
			{
				path: "dailyguide",
				component: () => import("../views/DailyGuide/Index.vue"),
				children: [
					{
						path: "",
						component: () => import("../views/DailyGuide/Months.vue"),
					},
					{
						path: "m/:month",
						component: () => import("../views/DailyGuide/ByMonth.vue"),
					},
					{
						path: "m/:month/:lessonId",
						component: () => import("../views/DailyGuide/View.vue"),
					},
					{
						path: "m/:month/:lessonId/edit",
						component: () => import("../views/DailyGuide/Edit.vue"),
					},
				]
			},
			{
				path: "dailyguide/add",
				component: () => import("../views/DailyGuide/Add.vue"),
			},
			{
				path: "dailypower",
				component: () => import("../views/DailyPower/Index.vue"),
				children: [
					{
						path: "",
						component: () => import("../views/DailyPower/Months.vue"),
					},
					{
						path: "m/:month",
						component: () => import("../views/DailyPower/ByMonth.vue"),
					},
					{
						path: "m/:month/:lessonId",
						component: () => import("../views/DailyPower/View.vue"),
					},
					{
						path: "m/:month/:lessonId/edit",
						component: () => import("../views/DailyPower/Edit.vue"),
					},

				]
			},
			{
				path: "dailypower/add",
				component: () => import("../views/DailyPower/Add.vue"),
			},
			{
				path: "daankwaabodoo",
				component: () => import("../views/NkwaBodo/Index.vue"),
				children: [
					{
						path: "",
						component: () => import("../views/NkwaBodo/Months.vue"),
					},
					{
						path: "m/:month",
						component: () => import("../views/NkwaBodo/ByMonth.vue"),
					},
					{
						path: "m/:month/:lessonId",
						component: () => import("../views/NkwaBodo/View.vue"),
					},
					{
						path: "m/:month/:lessonId/edit",
						component: () => import("../views/NkwaBodo/Edit.vue"),
					},
			
				]
			},
			{
				path: "daankwaabodoo/add",
				component: () => import("../views/NkwaBodo/Add.vue"),
			},
			{
				path: "news",
				component: () => import("../views/News/Index.vue"),
				children: [
					{
						path: "",
						component: () => import("../views/News/List.vue"),
					},
					{
						path: "n",
						redirect: "/news"
					},
					{
						path: "n/:newsId",
						component: () => import("../views/News/View.vue"),
					},
					{
						path: "n/:newsId/edit",
						component: () => import("../views/News/Edit.vue"),
					},
					{
						path: "add",
						component: () => import("../views/News/Add.vue"),
					},
				]
			},
			{
				path: "donations",
				component: () => import("../views/Donations/Index.vue"),
				children: [
					{
						path: "",
						component: () => import("../views/Donations/List.vue"),
					}
				]
			},
			{
				path: "subscribers",
				component: () => import("../views/Subscribers/Index.vue"),
				children: [
					{
						path: "",
						component: () => import("../views/Subscribers/List.vue"),
					}
				]
			},
			{
				path: "subscriptions",
				component: () => import("../views/Subscribers/Subscriptions.vue"),
				children: [
					{
						path: "",
						component: () => import("../views/Subscribers/List.vue"),
					}
				]
			},
			{
				path: "settings",
				component: () => import("../views/Settings/Index.vue"),
			},
			{
				path: "users",
				component: () => import("../views/Users/Index.vue"),
				children: [
					{
						path: "",
						component: () => import("../views/Users/List.vue"),
					},
					{
						path: "u",
						redirect: "/users"
					},
					{
						path: "u/:userId",
						component: () => import("../views/Users/View.vue"),
					},
					{
						path: "u/:userId/edit",
						component: () => import("../views/Users/Edit.vue"),
					},
					{
						path: "add",
						component: () => import("../views/Users/Add.vue"),
					},
				]
			},


			{
				path: "dailysuperfood",
				component: () => import("../views/DailySuperFood/Index.vue"),
				children: [
					{
						path: "",
						component: () => import("../views/DailySuperFood/Months.vue"),
					},
					{
						path: "m/:month",
						component: () => import("../views/DailySuperFood/ByMonth.vue"),
					},
					{
						path: "m/:month/:lessonId",
						component: () => import("../views/DailySuperFood/View.vue"),
					},
					{
						path: "m/:month/:lessonId/edit",
						component: () => import("../views/DailySuperFood/Edit.vue"),
					},

				]
			},
			{
				path: "dailysuperfood/add",
				component: () => import("../views/DailySuperFood/Add.vue"),
			},
			{
				path: "disputes",
				component: () => import("../views/Disputes/Index.vue"),
			}


		],
		meta: {
			requiresAuth: true,
			title: "Dashboard"
		},
	},
	{
		path: "/login",
		component: () => import("../views/Auth/Login.vue"),
	},
	{
		path: '/app/delete',
		component: DeleteApp,
	}
]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
	if (requiresAuth && !await firebase.getCurrentUser()){
		next('/login')
		return
	}
	next()
})

export default router
